<template>
    <div>

        <div>
            <el-select size="small" v-model="siteIndex" @change="changeGroup">
                <el-option v-for="item in siteList" :key="item.group_id" :label="item.group_name" :value="item.group_id" />
            </el-select>
        </div>

        <div class="grids">
            <div>
                <ul>
                    <li>
                        <P>监控车位数</P>
                        <P>{{dataValue.total}}<span>个</span></P>
                    </li>
                    <li>
                        <P>占位</P>
                        <P>{{dataValue.seat_num}}</P>
                    </li>
                    <li>
                        <P>空闲</P>
                        <P>{{dataValue.idle_num}}</P>
                    </li>
                    <li>
                        <P>充电中</P>
                        <P>{{dataValue.on_num}}</P>
                    </li>
                </ul>
            </div>
            <div style="display:flex;justify-content: space-between;">
                <ul>
                    <li class="bo">
                        <P>使用率</P>
                        <P>{{dataValue.use_rate}}<span>%</span></P>
                    </li>
                    <li class="bo">
                        <P>空闲率</P>
                        <P>{{dataValue.idle_rate}}<span>%</span></P>
                    </li>
                    <li class="bo">
                        <P>占用率</P>
                        <P>{{dataValue.seat_rate}}<span>%</span></P>
                    </li>
                </ul>
<!--                <div class="hzType">-->
<!--                    <a :class="hzType==1?'sel':''" @click="setHzType(1)">昨</a><a :class="hzType==2?'sel':''" @click="setHzType(2)">月</a>-->
<!--                </div>-->
            </div>
        </div>

        <div class="search">
            <el-input v-model="page.device_no" size="small" clearable placeholder="枪编号"></el-input>
            <el-input v-model="page.camera_no" size="small" clearable placeholder="相机编号"></el-input>
            <el-button type="primary" size="small" icon="el-icon-search" plain @click="search()">查询</el-button>
            <el-button size="small" icon="el-icon-refresh" plain>重置</el-button>
        </div>


        <div class="gird2">
            <div class="item" v-for="(item, index) in dataList" :key="index">
                <p @click="openRoute(item)" class="p">相机编号：{{item.id}} <span>历史记录</span></p>
                <ul>
                    <li>
                        <p>{{item.A}} {{item.B}}</p>
                        <p>枪编号: {{item.C}}</p>
                        <p>车牌号: {{item.D}}</p>
                        <p>驶入时间: {{item.E}}</p>
                        <p>停放时长: {{item.F}}分钟</p>
                        <p>占位类型: {{item.G}}</p>
                    </li>
                    <li>
                        <p>{{item.A2}} {{item.B2}}</p>
                        <p>枪编号 {{item.C2}}</p>
                        <p>车牌号: {{item.D2}}</p>
                        <p>驶入时间: {{item.E2}}</p>
                        <p>停放时长: {{item.F2}}分钟</p>
                        <p>占位类型: {{item.G2}}</p>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
import Tables from '@/components/tables'
import { listenerCount } from 'process';
export default ({

    components: {
        Tables,
    },
    data() {
        return {
            siteIndex: 1, //选择的站点
            hzType: 1, //1昨 2月
            siteList: [], //站点列表
            page: {
                key: ""
            },
            dataValue: {A:10,B:2,C:3,D:3,E:10,F:20,G:30}, //汇总数据
            dataList: [{id:"500000",A:"01",B:"占位",C:"12802093-1",D:"苏ED12345",E:"2023.04.23 12:53:53",F:"187",G:"超时",A2:"02",B2:"充电中",C2:"12802093-1",D2:"苏ED12345",E2:"2023.04.23 12:53:53",F2:"187",G2:""}], //相机列表
        }
    },
    mounted(){
        const params = {
            token: this.$store.state.user.token,
            page: 1,
            size: 100,
        }
        this.$api.Device.GetGroupList(params).then((res) => {
            this.siteList = res.data;
            this.siteIndex = res.data[0].group_id;
            this.list();
        });
    },
    methods: {
        openRoute(d) {
            this.$router.push({
                name:"monitoring-history",
                query:{id:d.id}
            });
        },
        list(){ //获取数据列表
            const params = {
                token: this.$store.state.user.token,
                group_id: this.siteIndex,
                hzType: this.hzType
            }
            this.$api.Rf.GetmPark(params).then((res) => {
                this.dataValue=res;
            });
            this.search();
        },
        changeGroup(){ //修改场站
            this.page.device_no = this.page.camera_no = "";
            this.list();
        },
        setHzType(tp){
            this.hzType = tp;
            this.list();
        },
        search(){ //搜索数据
            const params = {
                token: this.$store.state.user.token,
                group_id: this.siteIndex,
                device_no: this.page.device_no,
                camera_no: this.page.camera_no,
            }
            this.$api.Rf.GetParkInfo(params).then((res) => {
                this.dataList=res;
            });
        }
    }
})
</script>


<style lang="scss" scoped>
* {
    margin: 0px;
    padding: 0px
}

.grids {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px; //列距
    margin-top: 20px;

    div {
        background: white;
        padding: 15px 20px;

        ul {
            margin: 0px;
            padding: 0px;
            display: flex;
            list-style: none;

            li {
                margin-right: 20px;
                border: 1px solid #606266;
                border-radius: 5px;
                padding: 10px;
                width: 80px;

                p:first-child {
                    color: #606266;
                    font-size: 15px;
                    font-weight: 400;
                }

                p:nth-child(2) {
                    font-size: 30px;
                    font-weight: bold;

                    span {
                        font-size: 15px;
                        font-weight: 400;
                        color: #606266;
                        margin-left: 5px;
                    }
                }
            }

            li:first-child {
                border: none;
            }
        }

        .bo {
            border: none;
        }
    }
    .hzType{
        a{
            border: 1px solid #666666;
            padding: 2px;
            margin-left: 3px;
            color: #999;
        }
        .sel{
            color: #000;
        }
    }
}

.search {
    padding: 15px 20px;
    display: flex;
    background: white;
    margin-top: 20px;

    :deep(.el-input) {
        width: 200px;
        margin-right: 20px;
    }

    :deep(.el-button--small) {
        padding: 9px 15px;
    }
}

.gird2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px; //列距
    margin-top: 20px;
    background: white;
    padding: 15px 20px;

    .item {
        border: 1px solid #606266;

        .p {
            height: 50px;
            line-height: 50px;
            padding: 0px 15px;
            border-bottom: 1px solid #606266;

            span {
                float: right;
                border: 1px solid;
                border-radius: 10px;
                line-height: 25px;
                margin-top: 11px;
                padding: 0px 8px;
                font-size: 13px;
                cursor: pointer;
            }
        }

        ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            list-style: none;
            font-size: 14px;

            li {
                padding: 0px 15px;


                p {
                    line-height: 40px;
                }
            }

            li:first-child {
                border-right: 1px solid #606266;
            }
        }
    }
}
</style>